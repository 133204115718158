import React, { Component } from "react";
import { graphql } from "gatsby";
import { Link, injectIntl } from "gatsby-plugin-intl";
import styled from "styled-components";
import breakpoint from "styled-components-breakpoint";
import Obfuscate from "react-obfuscate";

import { buildImageObj } from '../lib/helpers'
import { imageUrlFor } from '../lib/image-url'
import BlockText from '../components/block-text'

import GraphQLErrorList from "../components/graphql-error-list";
import SEO from "../components/seo";
import BorderGrid, { BorderGridCol } from "../components/border-grid";
import Card from "../components/card";
import Content, { ContentDivider, ContentImage, ContentImageContainer } from "../components/content";
import { Heading2, Heading3 } from "../components/heading";
import Mono from "../components/mono";
import RatioBox from "../components/ratio-box";
import Footer from "../components/footer";

export const query = graphql`
query InfoPageQuery {
    site: sanitySiteSettings(_id: {regex: "/(drafts.|)siteSettings/"}) {
      title
      _rawDescription
      keywords
    }
    infopage: sanityInfopage {
      id
      infopageImage {
        _key
        _type
        caption
        alt
        hotspot {
            _key
            _type
            x
            y
            height
            width
        }
        asset {
          _id
          id
          url
          fixed {
            aspectRatio
            width
            height
            src
            srcSet
            srcWebp
            srcSetWebp
          }
          fluid {
            aspectRatio
            src
            srcSet
            srcWebp
            srcSetWebp
            sizes
          }
        }
      }
      _rawInfopageTitle
      _rawInfopageBody
      _rawTicketsTitle
      _rawTicketsBody
      _rawFootnotes
    }
  }  
`;

const InfoPage = ({ intl, ...props }) => {
    const { data, errors } = props;
    const locale = intl.locale || "en";

    if (errors) {
        return (
            <Layout>
                <GraphQLErrorList errors={errors} />
            </Layout>
        );
    }

    const site = (data || {}).site;

    if (!site) {
        throw new Error(
            'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
        );
    }

    return (
        <React.Fragment>
            <SEO
                title={`${intl.messages.menu_info}`}
                description={site._rawDescription[locale]}
                keywords={site.keywords}
                lang={locale}
            />
            <BorderGrid>
                <BorderGridCol size={{ sm: 1 / 1, md: 1 / 2 }}>
                    <RatioBox ratio="1/1">
                        <ContentImage src={imageUrlFor(buildImageObj(data.infopage.infopageImage))
                            .width(960)
                            .height(960)
                            .auto('format')
                            .quality(80)
                            // .saturation(-100)
                            .url()} />
                    </RatioBox>
                    <StyledLeftColContent>
                        <ContentDivider />
                        <Content>
                            <Mono>
                                <BlockText blocks={data.infopage._rawFootnotes[intl.locale]} />
                                <Obfuscate email="veera.moilanen@grafia.fi" />
                            </Mono>
                        </Content>
                    </StyledLeftColContent>
                </BorderGridCol>
                <BorderGridCol size={{ xs: 1 / 1, md: 1 / 2 }}>
                    <Content>
                        <Heading2>{data.infopage._rawInfopageTitle[intl.locale]}</Heading2>
                        <Mono>
                            <BlockText blocks={data.infopage._rawInfopageBody[intl.locale]} />
                        </Mono>
                    </Content>
                    <ContentDivider />
                    <Content>
                        <Heading2>{data.infopage._rawTicketsTitle[intl.locale]}</Heading2>
                        <Mono>
                            <BlockText blocks={data.infopage._rawTicketsBody[intl.locale]} />
                        </Mono>
                    </Content>
                    <StyledRightColContent>
                        <ContentDivider />
                        <Content>
                            <Mono>
                                <BlockText blocks={data.infopage._rawFootnotes[intl.locale]} />
                                <Obfuscate email="veera.moilanen@grafia.fi" />
                            </Mono>
                        </Content>
                    </StyledRightColContent>
                </BorderGridCol>
            </BorderGrid>
            <ContentDivider />
            <Footer />
        </React.Fragment>
    );
};

const StyledLeftColContent = styled.div`
    display: initial;

    @media (max-width: 48em) {
        display: none;
    }
`;

const StyledRightColContent = styled.div`
    display: initial;
    
    @media (min-width: 48em) {
        display: none;
    }
`;


export default injectIntl(InfoPage);
